<template>
    <div class="num_charts" ref="box_charts">
        <div ref="times_charts" :style="boxStyle"></div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            boxStyle: {},
            companyName: [],
            nondenseNumSum: [],
            denseNumSum: [],
            totalSum: [],
            chart: null
        }
    },
    created() {
        this.$nextTick(() => {
            this.setStyle()
            this.setObserver()
        })
    },
    props: {
        list: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    watch: {
        list: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    let l_s = [],
                        l_on = [],
                        l_off = [],
                        l_total = []

                    val.forEach(item => {
                        l_s.push(item.companyName)
                        l_on.push(item.nondenseNumSum)
                        l_off.push(item.denseNumSum)
                        l_total.push(item.totalSum)
                    })
                    this.companyName = l_s
                    this.nondenseNumSum = l_on
                    this.denseNumSum = l_off
                    this.totalSum = l_total
                    console.log(this.companyName)
                    console.log(this.nondenseNumSum)
                    console.log(this.denseNumSum)
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                } else {
                    this.companyName = []
                    this.nondenseNumSum = []
                    this.denseNumSum = []
                    this.totalSum = []
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                }
            }
        }
    },
    methods: {
        setObserver() {
            let myObserve = new ResizeObserver(() => {
                echarts.dispose(this.chart)
                this.setStyle()
            })
            myObserve.observe(this.$el)
        },
        setChart() {
            var userChart = echarts.init(this.$refs['times_charts'])
            var option = {
                title: {
                    text: '分公司过疏过密次数统计图',
                    x: 'center',
                    y: 'top',
                    textAlign: 'left'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['过疏次数', '过密次数', '总次数'],
                    left: 'center',
                    bottom: '10px'
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: this.companyName
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '过疏次数',
                        type: 'bar',
                        data: this.nondenseNumSum
                    },
                    {
                        name: '过密次数',
                        type: 'bar',
                        data: this.denseNumSum
                    },
                    {
                        name: '总次数',
                        type: 'line',
                        data: this.totalSum
                    }
                ]
            }

            userChart.setOption(option)
            this.chart = userChart
        },
        setStyle() {
            let s = this.$refs['box_charts']
            let h = $(s).height() + 'px'
            console.log(h)
            this.boxStyle = {
                height: h
            }
            this.$nextTick(() => {
                this.setChart()
            })
        }
    }
}
</script>
<style lang="scss" scoped="num_charts">
.num_charts {
    height: 100%;
}
</style>
